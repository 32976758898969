import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import BoxModel from '../BoxModel/index'
import './input.css'

export default () => {
  const data = useStaticQuery(graphql`
  query {
    
    hotChocolate: file(relativePath: {eq: "ciocco-crem.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hotChocolatePac: file(relativePath: {eq: "ciocco-crem-pac.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chocolateMavalerio: file(relativePath: {eq: "chocolate-mavalerio.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chantilly: file(relativePath: {eq: "chantilly.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    frozenNonFlavor: file(relativePath: {eq: "frozen-nonflavor.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    invernoFrozen: file(relativePath: {eq: "inverno-frozen.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ices: file(relativePath: {eq: "ices.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    frozens: file(relativePath: {eq: "frozens.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    frozenFruit: file(relativePath: {eq: "frozen-fruit.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    xarope: file(relativePath: {eq: "xarope.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iceCremTop: file(relativePath: {eq: "ice-cream-topping.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iceCremTopping: file(relativePath: {eq: "ice-cream-topping-gelcrem.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sugar: file(relativePath: {eq: "acucar-mascavo.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sweetener: file(relativePath: {eq: "sweetener.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hotMachineChocolate: file(relativePath: {eq: "hot-machine-chocolate.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    milkMachine: file(relativePath: {eq: "milk-machine.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    guaraniSugar: file(relativePath: {eq: "guarani-sugar.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chai: file(relativePath: {eq: "chai.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tea: file(relativePath: {eq: "tea.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    PeachTea: file(relativePath: {eq: "peach-tea.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lav: file(relativePath: {eq: "lav.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    nelisul: file(relativePath: {eq: "nelisul.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cappuccino: file(relativePath: {eq: "cappuccino.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vanillaFrozen: file(relativePath: {eq: "vanilla-frozen.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`)
  return (
  <section className="input-page">
      <div className="input-container">
        <BoxModel
          title="Cappuccino Tradicional, Cappucino Canela"
          description="Cappuccino mineiro premium 1kg"
          data={data.cappuccino.childImageSharp.fixed}/>
        <BoxModel
          title="Ciocco Crem"
          description="Chocolate tipo europeu, pote com 1,5 Kg"
          data={data.hotChocolate.childImageSharp.fixed}/>
        <BoxModel
          title="Ciocco Crem"
          description="Chocolate tipo europeu 200g" 
          data={data.hotChocolatePac.childImageSharp.fixed}/>
        <BoxModel
          title="Chocolate Mavalério 50% cacau"
          description=" Pacote com 1kg"
          data={data.chocolateMavalerio.childImageSharp.fixed}/>
        <BoxModel
          title="Chantilly Hulala"
          description=" Caixa com1 lt"
          data={data.chantilly.childImageSharp.fixed}/>
        <BoxModel
          title="Base Neutra Gelcrem"
          description="Base para frozen 1,5 kg"
          data={data.frozenNonFlavor.childImageSharp.fixed}/>
        <BoxModel
          title="Frozen Baunilha"
          description="Pacote com 600g"
          data={data.vanillaFrozen.childImageSharp.fixed}/>
        <BoxModel
          title="Frozens Inverno D'Italia"
          description="Frozen Avelã, Café,Cappuccino, Cappuccino Avelã, Cappuccino Vanilla, Chocolate Avelã"
          data={data.invernoFrozen.childImageSharp.fixed}/>
        <BoxModel
          title="Ice Cappuccino e Ice Chocolate"
          description="Pacote com 480g"
          data={data.ices.childImageSharp.fixed}/>
        <BoxModel
          title="Frozen Chocolate e Frozen Cappuccino"
          description=" Pacote com 500g"
          data={data.frozens.childImageSharp.fixed}/>
        <BoxModel
          title="Frozen Fruit"
          description="Sabores Uva, Limão, Morango, Maracujá 600g"
          data={data.frozenFruit.childImageSharp.fixed}/>
        <BoxModel
          title="Xaropes Routin 1883"
          description=""
          data={data.xarope.childImageSharp.fixed}/>
        <BoxModel
          title="Caldas Marvi"
          description="Sabores Chocolate, Morango e Caramelo"
          data={data.iceCremTop.childImageSharp.fixed}/>
        <BoxModel
          title="Caldas TopCrem"
          description="Sabores Chocolate, Caramelo, Menta, Kiwi, Abacaxi, Morango, Framboesa e Frutas Vermelhas 1,2kg"
          data={data.iceCremTopping.childImageSharp.fixed}/>
        <BoxModel
          title="Açucar Guarani"
          description="Sachê 5g Caixa com 1000 Unidades"
          data={data.guaraniSugar.childImageSharp.fixed}/>
        <BoxModel
          title="Açucar Mascavo Sache Bom Sabor"
          description="Sachê 5g Caixa com 1000 Unidades"
          data={data.sugar.childImageSharp.fixed}/>
        <BoxModel
          title="Adoçante Stevia Plus  Sachê"
          description="Sachê 6g Caixa com 1000 Unidades"
          data={data.sweetener.childImageSharp.fixed}/>
        <BoxModel
          title="Chocolate Quente Qualimax"
          description="Pacote com 1kg, Caixa com 10 Unidades"
          data={data.hotMachineChocolate.childImageSharp.fixed}/>
        <BoxModel
          title="Leite em pó Qualimax"
          description="Pacote com 510g, Caixa com 20 Unidades"
          data={data.milkMachine.childImageSharp.fixed}/>
        <BoxModel
          title="Chai Latte Gelcrem"
          description="Pacotes com 520g e monodose "
          data={data.chai.childImageSharp.fixed}/>
        <BoxModel
          title="Chá Mate de Limão Gelcrem"
          description="Pacote com 600g"
          data={data.tea.childImageSharp.fixed}/>
        <BoxModel
        title="Chá Mate de Pêssego Gelcrem"
        description="Pacote com 600g"
        data={data.PeachTea.childImageSharp.fixed}/>
        <BoxModel
          title="CoffeeLav"
          description="Descalsificante para máquina 1kg"
          data={data.lav.childImageSharp.fixed}/>
        <BoxModel
          title="Preparo de Morango"
          description="Pote com 4,1kg"
          data={data.nelisul.childImageSharp.fixed}/>
      </div>
  </section>
  );
}

