import React from 'react';

import Layout from '../../components/Layout/layout';
import Input from '../../components/Input/input'
import SEO from "../../components/seo"


function inputPage() {
  return (
        <Layout>
            <SEO title="Inputs"/>
            <Input/>            
        </Layout>
    )
}

export default inputPage;